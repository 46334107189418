/* Font */

@font-face {
	font-family: "averta_stdsemibold";
	src: url("./assets/Font/avertastd-semibold-webfont.woff2") format("woff2"),
		url("./assets/Font/avertastd-semibold-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "averta_stdregular";
	src: url("./assets/Font/avertastd-regular-webfont.woff2") format("woff2"),
		url("./assets/Font/avertastd-regular-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "averta_stdlight";
	src: url("./assets/Font/avertastd-light-webfont.woff2") format("woff2"),
		url("./assets/Font/avertastd-light-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "averta_stdblack";
	src: url("./assets/Font/avertastd-black-webfont.woff2") format("woff2"),
		url("./assets/Font/avertastd-black-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "averta_stdextrabold";
	src: url("./assets/Font/avertastd-extrabold-webfont.woff2") format("woff2"),
		url("./assets/Font/avertastd-extrabold-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "averta_stdbold";
	src: url("./assets/Font/avertastd-bold-webfont.woff2") format("woff2"),
		url("./assets/Font/avertastd-bold-webfont.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

/* Customize UI */

:root {
	--amplify-colors-primary-10: #e6e9ec;
	--amplify-colors-primary-20: #cdd3d9;
	--amplify-colors-primary-40: #9ba7b3;
	--amplify-colors-primary-60: #697b8d;
	--amplify-colors-primary-80: #374e67;
	--amplify-colors-primary-100: #062342;
	--amplify-colors-background-primary: #f5f6fa;
	--amplify-components-button-primary-hover-background-color: #374e67;
	--amplify-internal-button-border-color: #1e3854;
	--amplify-components-button-primary-focus-background-color: #374e67;

	/* CONTAINER */
	--amplify-components-authenticator-router-box-shadow: none;
	--amplify-components-authenticator-router-border-color: transparent;
	--amplify-components-authenticator-router-border-width: 0;
	--amplify-components-authenticator-router-border-style: none;
	--amplify-components-authenticator-container-width-max: 100%;

	/* FORM FIELD */
	--amplify-components-fieldcontrol-color: black;
	--amplify-components-fieldcontrol-font-size: 16px;
	--amplify-components-fieldcontrol-line-height: 150%;
	--amplify-components-fieldcontrol-padding-block-start: 10px;
	--amplify-components-fieldcontrol-padding-block-end: 10px;
	--amplify-components-fieldcontrol-padding-inline-start: 14px;
	--amplify-components-fieldcontrol-padding-inline-end: 14px;
	--amplify-components-fieldcontrol-transition-duration: 200ms;
	--amplify-components-fieldcontrol-border-color: #abb8ce;
	--amplify-components-fieldcontrol-border-radius: 8px;
	--amplify-components-fieldcontrol-border-style: solid;
	--amplify-components-fieldcontrol-border-width: 1px;
	--amplify-components-fieldcontrol-outline-color: transparent;
	--amplify-components-fieldcontrol-outline-style: none;
	--amplify-components-fieldcontrol-outline-width: 0px;
	--amplify-components-fieldcontrol-outline-offset: 0px;
	--amplify-components-input-color: black;
	--amplify-components-input-border-color: #abb8ce;
	--amplify-components-input-font-size: 16px;
	--amplify-components-input-focus-border-color: #abb8ce;
	--amplify-components-fieldcontrol-focus-box-shadow: none;

	--amplify-components-field-label-color: #3d4a67;
	--amplify-components-field-font-size: 14px;

	/* BUTTON */
	--amplify-components-button-primary-border-width: 0px;
	--amplify-components-button-primary-background-color: #244fec;
	--amplify-components-button-primary-hover-background-color: #1c3bd9;
	--amplify-components-button-primary-focus-background-color: #1c3bd9;
	--amplify-components-button-primary-active-background-color: #1c3bd9;
	--amplify-components-button-font-weight: 600;

	--amplify-colors-font-interactive: #244fec;
	--amplify-colors-font-hover: #244fec;
	--amplify-components-button-link-hover-background-color: transparent;

	/* ALERT */
	--amplify-components-alert-error-color: #b42318;
	--amplify-components-alert-error-background-color: #fffbfa;
	--amplify-components-icon-height: 20px !important;
	--amplify-components-alert-icon-size: 20px !important;

	/* SELECT */
	--amplify-components-field-gap: 6px;
	--amplify-components-select-color: #5e7499;
	--amplify-components-select-background-color: white;
	--amplify-components-select-border-color: #abb8ce;
	--amplify-components-fieldcontrol-focus-border-color: #abb8ce;
	--amplify-components-select-padding-inline-end: var(
		--amplify-components-fieldcontrol-padding-inline-end
	);
	--amplify-components-select-icon-wrapper-right: 7px;
}

html,
body,
#root {
	font-family: "Inter", sans-serif;
}

#root {
	display: flex;
	justify-content: center;
}

body {
	margin: 0;
	padding: 0;
	font-family: "Inter", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f5f6fa;
}

/* GENERAL AMPLIFY OVERRIDES */

.amplify-label {
	color: var(--Gray-700, #3d4a67) !important;
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 20px !important;
}

.amplify-input {
	background-color: white;
}

/* CUSTOM CHECKLIST */
.check-list {
	list-style: none;
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.check-list li {
	display: flex;
	align-items: center;
	gap: 8px;
	color: #4a5c7f;
	font-size: 14px;
	font-weight: 400;
}

.check-list li.cross-out svg rect {
	fill: #244fec !important;
}

/* CUSTOM INPUTS */

.input-code {
	width: 64px;
	height: 64px;
	border-radius: 8px;
	padding-block: 2px;
	padding-inline: 8px;
	border: 1px solid #abb8ce;
	text-align: center;
	font-weight: 700;
	outline-color: #244fec;
}

.input-code:focus-visible::placeholder {
	opacity: 0;
}

input,
select {
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

input::-webkit-input-placeholder {
	color: #5e7499;
}

input:focus-visible,
input:focus,
input:active,
input:focus-within,
select:focus-visible,
select:focus,
select:active,
select:focus-within {
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #dbe7fe !important;
}

@media screen and (max-width: 998px) {
	.input-code {
		width: 48px;
		height: 48px;
	}

	.go-back-button {
		left: 0 !important;
	}
}

/* AMPLIFY ERRORS */

.amplify-alert--error .amplify-alert__icon {
	display: none;
}

.amplify-alert--error::before {
	content: url("/src/assets/icons/errorIcon.svg");
}

.amplify-alert--error {
	border: 1px solid #fda29b;
	border-radius: 12px;
	align-items: start;
	font-size: 14px;
	padding: 16px;
}

.amplify-alert__dismiss {
	padding: 0px;
}

.amplify-icon {
	width: 20px !important;
	height: 20px !important;
}

/* CUSTOM SELECT */

.custom-select {
	position: relative;
	width: fit-content;
}

#signin .custom-select {
	margin-top: 40px;
	margin-inline: auto;
}

.custom-select-opener {
	padding: 12px 14px;
	border: 1px solid #abb8ce;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	display: flex;
	align-items: center;
	gap: 20px;
	background-color: white;
	cursor: pointer;
}

.custom-select-opener > div {
	display: flex;
	align-items: center;
	gap: 8px;
	line-height: 1;
}

.custom-select-options-container {
	display: none;
	overflow: hidden;
	position: absolute;
	top: calc(100% + 8px);
	left: 0;
	right: 0;
	width: 100%;
	max-height: 120px;
	background-color: white;
	border: 1px solid #abb8ce;
	box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
		0px 4px 6px -2px rgba(16, 24, 40, 0.03);
	border-radius: 8px;
}

.custom-select-options-container.opened {
	display: flex;
}

.custom-select-options-container > div {
	width: 100%;
	flex-direction: column;
	gap: 6px;
	overflow: auto;
	padding: 6px;
}

.custom-select-option {
	display: flex;
	align-items: center;
	gap: 8px;
	border: none;
	background-color: white;
	border-radius: 8px;
	width: 100%;
	padding: 4px;
	cursor: pointer;
}

.custom-select-option:hover {
	background-color: #f5f6fa;
}

.custom-select:has(.custom-select-options-container.opened)
	.custom-select-opener,
.custom-select-opener:hover {
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #dbe7fe !important;
}

@media screen and (max-width: 998px) {
	#signin .custom-select {
		margin-top: 40px;
		margin-bottom: 50px;
	}

	.custom-select label {
		display: none;
	}

	.custom-select-options-container {
		max-height: 90px;
		min-width: fit-content;
		left: unset;
	}

	#signup .custom-select-options-container {
		max-height: 120px;
	}
}

/* HS FORM */

#hubspotForm fieldset {
	max-width: 100%;
	padding: 0;
}

#hubspotForm fieldset:not(:first-child):not(:has(input[type="hidden"])),
#hubspotForm fieldset .hs-dependent-field .hs-form-field:not(:first-child) {
	margin-top: 20px;
}

#hubspotForm fieldset.form-columns-2 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	gap: 20px;
}

#hubspotForm fieldset.form-columns-2 > .hs-form-field {
	all: unset;
	flex-grow: 1;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	gap: 6px;
}

#hubspotForm .hs-form-field label,
#hubspotForm .hs-form-field label span {
	all: unset;
	color: var(--Gray-700, #3d4a67);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
}

#hubspotForm .hs-form-field .input {
	all: unset;
	position: relative;
	box-sizing: border-box;
	display: block;
}

#hubspotForm .hs-form-field .input input:not([type="checkbox"]),
#hubspotForm .hs-form-field .input textarea {
	position: relative;
	box-sizing: border-box;
	display: block;
	border-radius: 8px;
	border: 1px solid var(--Gray-300, #abb8ce);
	background: var(--Base-White, #fff);
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	padding: 10px 14px;
	width: 100%;

	color: var(--Gray-500, #5e7499);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
}

#hubspotForm .hs-form-field .input select {
	all: unset;
	position: relative;
	box-sizing: border-box;
	display: block;
	border-radius: 8px;
	border: 1px solid var(--Gray-300, #abb8ce);
	background: var(--Base-White, #fff);
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	padding: 10px 14px;
	width: 100%;

	color: var(--Gray-500, #5e7499);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
}

#hubspotForm .hs-form-field .input:has(> select)::after {
	content: "";
	width: 20px;
	height: 20px;
	background-image: url("/public/images/chevron-down.svg");
	position: absolute;
	right: 14px;
	top: 50%;
	transform: translateY(-50%);
	transition: all 0.3s ease-in-out;
	transform-origin: top;
}

#hubspotForm .hs-form-field .input:has(> select):focus-within:after {
	rotate: 180deg;
}

#hubspotForm .hs-error-msgs {
	all: unset;
	position: relative;
	box-sizing: border-box;
	display: block;
	border-radius: 12px;
	border: 1px solid var(--Error-300, #fda29b);
	background: var(--Error-25, #fffbfa);
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	padding: 16px;
	list-style: none;
}

#hubspotForm .hs-error-msgs .hs-error-msg,
#hubspotForm .hs-error-msgs li label {
	color: var(--Error-700, #b42318);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
}

#hubspotForm .legal-consent-container .input .inputs-list {
	list-style: none;
	padding: 0;
}

#hubspotForm .hs-form-booleancheckbox {
	display: flex;
	align-items: center;
}

#hubspotForm .hs-form-booleancheckbox input[type="checkbox"] {
	margin-right: 8px;
}

#hubspotForm .hs-button.primary {
	border-radius: 8px;
	border: 1px solid var(--Primary-600, #244fec);
	background: var(--Primary-600, #244fec);
	/* Shadow/xs */
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	display: flex;
	padding: 10px 18px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	align-self: stretch;

	color: var(--Base-White, #fff);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
	width: 100%;
	margin-top: 20px;
}

#hubspotForm .hs-error-msgs {
	display: none !important;
}
